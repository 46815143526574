



































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Util } from "@/common/Views/comm/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
//引入请求ts
import { FarmData } from "@/views/components/FarmGetData";
//引入养殖地模板
import FarmAdress from "@/views/components/input/FarmAdress.vue";
//引入养殖企业模板
import FarmEnterprise from "@/views/components/input/FarmEnterprise.vue";
//引入养殖场模板
import FarmHouse from "@/views/components/input/FarmHouse.vue";
//引入电子耳标模板
import FarmLabelNumber from "@/views/components/input/FarmLabelNumber.vue";
//引入电子耳标号模板
import FarmOldLabelNumber from "@/views/components/input/FarmOldLabelNumber.vue";
//引入登记时间模板
import FarmLogData from "@/views/components/input/FarmLogData.vue";
//引入搜索 重置模板
import FarmSearchAndClear from "@/views/components/input/FarmSearchAndClear.vue";
//引入通用搜索模板
import FarmPublicSelect from "@/views/components/input/FarmPublicSelect.vue";
import FarmOriginalNumber from "@/views/components/input/FarmOriginalNumber.vue";

import { Axios, AxiosData } from "@/common/http/http";
import { BasicsClass } from "@/common/BasicsClass";
@Component({
    components: {
        FarmAdress,
        FarmEnterprise,
        FarmHouse,
        FarmLabelNumber,
        FarmOldLabelNumber,
        FarmLogData,
        FarmSearchAndClear,
        FarmPublicSelect,
        FarmOriginalNumber,
    },
})
export default class newBreedingHistory extends Vue {
    private form: any = {
        groupCode: "",
        companyId: "",
        farmId: "",
        labelNumber: "",
        oldLabelNumber: "",
        originalNumber: "",
        breedId: "",
        typeConfId: "",
        startTime: "",
        endTime: "",
    };

    private IsQueryFlag: boolean = false;
    private langType = this.$i18n.locale;
    private infoType = false;
    public infoData = {};
    //展示详细信息
    private ShowDetail(row: any) {
        this.infoData = row;
        this.infoType = true;
    }
    //养殖地数据
    private FarmAdressList: any[] = [];
    private getFarmAdress() {
        new FarmData().getFarmAdress((res: any) => {
            const data = new DeepLoop().deepCopy(res.data);
            this.FarmAdressList = data;
        });
    }
    private changeAdress(groupCode: any) {
        //赋值
        this.form.groupCode = groupCode[groupCode.length - 1];
        //清除企业数据
        this.FarmEnterpriseList = [];
        //清除实例中的属性
        this.$refs.FarmEnterprise.companyId = "";
        this.$refs.FarmHouseRef.farmId = "";
        //清除form中的属性
        this.form.companyId = "";
        this.form.farmId = "";
        //重新请求养殖企业的数据
        this.getFarmEnterprise();

        //清除养殖场数据
        this.FarmHouse = [];
        //重新请求养殖厂数据
        this.getFarmHouse();
    }

    //养殖企业数据
    private FarmEnterpriseList: any[] = [];
    private getFarmEnterprise() {
        new FarmData().getFarmEnterprise(this.form.groupCode, (data: any) => {
            const FarmEnterpriseListData = new DeepLoop().deepCopy(data.data);

            FarmEnterpriseListData.unshift({
                id: "",
                name: this.$i18n.t("common.placeholder.all"),
            });
            this.FarmEnterpriseList = FarmEnterpriseListData;
        });
    }
    private changeEnterprise(companyId: any) {
        //赋值
        this.form.companyId = companyId;

        //清除养殖场数据
        this.FarmHouse = [];
        //清除实例中的属性
        this.$refs.FarmHouseRef.farmId = "";
        //清除form中的属性
        this.form.farmId = "";
        //重新请求养殖厂数据
        this.getFarmHouse();
    }

    //养殖厂数据
    private FarmHouse: any[] = [];
    private getFarmHouse() {
        new FarmData().getFarmHouse(
            this.form.groupCode,
            this.form.companyId,
            (data: any) => {
                const FarmHouseData = new DeepLoop().deepCopy(data.data);

                FarmHouseData.unshift({
                    id: "",
                    name: this.$i18n.t("common.placeholder.all"),
                });
                this.FarmHouse = FarmHouseData;
            }
        );
    }
    private changeFarmHouse(farmId: any) {
        //赋值
        this.form.farmId = farmId;
        //执行请求品种列表
        this.getAllBreed(farmId);
        this.getAllType(farmId);
    }

    //生物芯片耳标号
    private changLabelNumber(labelNumber: any) {
        //赋值
        this.form.labelNumber = labelNumber;
    }

    //电子耳标号
    private changeOldLabelNumber(oldLabelNumber: any) {
        //赋值
        this.form.oldLabelNumber = oldLabelNumber;
    }
    //原厂耳标
    private changeOriginalNumber(originalNumber: any) {
        //赋值
        this.form.originalNumber = originalNumber;
    }

    //登记时间
    private changeLoginDate(loginTime: any) {
        let time: any = Util.handleStartTimeAndEndTimenohms(loginTime);
        this.form.startTime = time.startTime;
        this.form.endTime = time.endTime;
    }

    //品种
    private breedList: any[] = [];
    private getAllBreed(farmId: any) {
        this.breedList = [];
        new FarmData().getAllBreed(farmId, (data: any) => {
            this.breedList = data.data;
        });
    }
    private changeBreed(breed: any) {
        //赋值
        this.form.breedId = breed;
    }

    //类型
    private TypeList: any[] = [];
    private getAllType(farmId: any) {
        this.TypeList = [];
        new FarmData().getAllType(farmId, (data: any) => {
            this.TypeList = data.data;
        });
    }
    private changeType(type: any) {
        //赋值
        this.form.typeConfId = type;
    }

    //查询
    private searchDataList: any[] = [];
    private sendUrl: string = "/animal/web/animalMark/animalRecordList";
    private searchList(list: any) {
        this.searchDataList = [];
        this.searchDataList = list.data;
        this.tablePage.totalCount = list.page.totalCount;
        this.tablePage.pageNo = list.page.pageNo;
    }

    //请求数据
    private getAllDataList() {
        new BasicsClass().BasicGet(
            this.sendUrl,
            { ...this.tablePage, ...this.form },
            false,
            false,
            (data: any) => {
                this.searchDataList = [];
                this.searchDataList = data.data;
                this.tablePage.totalCount = data.page.totalCount;
                this.tablePage.pageNo = data.page.pageNo;
            }
        );
    }

    //分页
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    //只为1的分页
    private tablePageOne: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    //分页选择请求
    private handlePageChange(val: any) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        //请求列表  未写
        this.getAllDataList();
    }

    //请求数据  初始  或者  重置使用
    private firstDataClearData() {
        this.getFarmAdress();
        this.getFarmEnterprise();
        this.getFarmHouse();
        this.getAllBreed("");
        this.getAllType("");
        new BasicsClass().BasicGet(
            this.sendUrl,
            { ...this.form, ...this.tablePageOne },
            false,
            false,
            (data: any) => {
                this.searchDataList = [];
                this.searchDataList = data.data;
                this.tablePage.totalCount = data.page.totalCount;
                this.tablePage.pageNo = data.page.pageNo;
            }
        );
    }

    //重置事件
    private restForm() {
        if (
            this.$route.query.farmId == "" ||
            this.$route.query.farmId != undefined
        ) {
            //判断 url中是否有 farmId
            //清空
            (this.$refs.FarmAdressRef as any).groupCode = [];
            (this.$refs.FarmEnterprise as any).companyId = "";
            (this.$refs.OldLabelNumberRef as any).oldLabelNumber = "";
            (this.$refs.OriginalNumberRef as any).originalNumber = "";
            (this.$refs.labelNumberRef as any).labelNumber = "";
            (this.$refs.LoginDateRef as any).Logtime = [];
            (this.$refs.breedRef as any).SelectVal = "";
            (this.$refs.TypeRef as any).SelectVal = "";

            //清空父组件form
            this.form = {
                groupCode: "",
                companyId: "",
                labelNumber: "",
                oldLabelNumber: "",
                originalNumber: "",
                breedId: "",
                typeConfId: "",
                startTime: "",
                endTime: "",
            };

            //请求所有数据接口
            this.firstDataClearData();
        } else {
            //清空
            (this.$refs.FarmAdressRef as any).groupCode = [];
            (this.$refs.FarmEnterprise as any).companyId = "";
            (this.$refs.FarmHouseRef as any).farmId = "";
            (this.$refs.OldLabelNumberRef as any).oldLabelNumber = "";
            (this.$refs.OriginalNumberRef as any).originalNumber = "";
            (this.$refs.labelNumberRef as any).labelNumber = "";
            (this.$refs.LoginDateRef as any).Logtime = [];
            (this.$refs.breedRef as any).SelectVal = "";
            (this.$refs.TypeRef as any).SelectVal = "";

            //清空父组件form
            this.form = {
                groupCode: "",
                companyId: "",
                farmId: "",
                labelNumber: "",
                oldLabelNumber: "",
                breedId: "",
                typeConfId: "",
                startTime: "",
                endTime: "",
            };

            //请求所有数据接口
            this.firstDataClearData();
        }
    }

    //导出/breeding/getBreedRecordList
    private sendExportUrl: any = "animalMark/exportRecordList";

    //点击查看详情
    private clickShowInfo(row: any) {
        let id: any = row.id;
        let labelNumber: any = row.labelNumber;
        // console.log(row)
        console.log(this.$route);
        if (this.$route.path == "/animalFile") {
            this.$router.push({
                path: "/animalFileInfo",
                query: {
                    id,
                    labelNumber,
                },
            });
        } else {
            this.$router.push({
                path: "/Filemanagement",
                query: {
                    id,
                    labelNumber,
                },
            });
        }
    }

    private CheckUser = async () => {
        let obj: any = {};
        obj.username = this.$route.query.username;
        obj.password = this.$route.query.password;
        obj.platform = "1";

        await this.login(obj);
        if (
            this.$route.query.farmId == "" ||
            this.$route.query.farmId != undefined
        ) {
            //判断 url中是否有 farmId
            this.form.farmId = this.$route.query.farmId;

            this.firstDataClearData();
        } else {
            this.firstDataClearData();
        }
    };
    private login(obj: any) {
        return new Promise((resolve: Function) => {
            new BasicsClass().BasicPost(
                "/animal/web/login",
                {
                    username: obj.username,
                    password: obj.password,
                },
                false,
                false,
                false,
                (data: any) => {
                    resolve();
                }
            );
        });
    }
    private showDiv: boolean = true;

    mounted() {
        if (this.$route.query.username && this.$route.query.password) {
            this.showDiv = false;
            this.IsQueryFlag = true;
            this.$refs.FarmHouseRef.isDisabled = true;
            this.CheckUser();
        } else {
            this.showDiv = true;
            this.IsQueryFlag = false;
            this.$refs.FarmHouseRef.isDisabled = false;
            this.firstDataClearData();
        }
        console.log(this.showDiv);
    }
    activated() {
        if (this.$route.query.username && this.$route.query.password) {
            this.showDiv = false;
            this.IsQueryFlag = true;
            this.$refs.FarmHouseRef.isDisabled = true;
            this.CheckUser();
        } else {
            this.showDiv = true;
            this.IsQueryFlag = false;
            this.$refs.FarmHouseRef.isDisabled = false;
            this.firstDataClearData();
        }
    }

    destroyed() {
        console.log(
            (this.$el.ownerDocument.children[0].children[1].style.overflow =
                "hidden")
        );
    }

    @Watch("$route", { immediate: true, deep: true })
    private handleRouter(newVal: any): void {
        console.log(this);
        if (this.$route.path == "/animalFile") {
            let timer = setTimeout(() => {
                clearTimeout(timer);
                this.$refs.DetailRef.parentElement.parentElement.style.overflow =
                    "scroll";
            }, 500);
        }
        // this.getLineF(this.nameList, this.dataList);
    }
}
