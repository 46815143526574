import { render, staticRenderFns } from "./newOestrumHistory.vue?vue&type=template&id=0be1b716&scoped=true&"
import script from "./newOestrumHistory.vue?vue&type=script&lang=ts&"
export * from "./newOestrumHistory.vue?vue&type=script&lang=ts&"
import style0 from "./newOestrumHistory.vue?vue&type=style&index=0&id=0be1b716&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be1b716",
  null
  
)

export default component.exports