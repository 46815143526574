


















import { Component, Prop, Vue , Watch} from "vue-property-decorator";
@Component({})
export default class FarmPublicSelect extends Vue{

    @Prop() SelectTitle:any

    @Prop() SelectListData:any

    private SelectVal:any = ''

    private SelectChange(){
        this.$emit('SelectChange' , this.SelectVal)
    }

}
