














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmFrozenSemenNumber extends Vue {
    private frozenSemenNumber: any = "";

    private changeFrozenSemenNumber() {
        this.$emit("changeFrozenSemenNumber", this.frozenSemenNumber);
    }
}
