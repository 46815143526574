import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import i18n from '@/vue-il8n/index'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: i18n.t('menu.login'),
        },
        component: () => import('../views/Login.vue'),
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        children: [
            {
                path: '/',
                redirect: '/login',
            },

            // {
            //   path: '/locationManage',
            //   name: 'locationManage.vue',
            //   meta: {
            //     title: '位置管理',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/locationManage/locationManage.vue'),
            // },
            // {
            //   path: '/assetRegistration',
            //   name: 'assetRegistration',
            //   meta: {
            //     title: '资产登记',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/assetRegistration/assetRegistration.vue'),
            // },
            // {
            //   path: '/assetUsage',
            //   name: 'assetUsage',
            //   meta: {
            //     title: '资产实时情况',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/assetUsage/assetUsage.vue'),
            // },
            // {
            //   path: '/loanApplication',
            //   name: 'loanApplication',
            //   meta: {
            //     title: '借出申请',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/loanApplication/loanApplication.vue'),
            // },
            // {
            //   path: '/loanApplicationDetail',
            //   name: 'loanApplicationDetail',
            //   meta: {
            //     title: '借出资产登记',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/loanApplication/loanApplicationDetail/loanApplicationDetail.vue'),
            // },
            // {
            //   path: '/compositionAnalysis',
            //   name: 'compositionAnalysis',
            //   meta: {
            //     title: '构成分析',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/compositionAnalysis/compositionAnalysis.vue'),
            // },
            // {
            //   path: '/usageStatistics',
            //   name: 'usageStatistics',
            //   meta: {
            //     title: '使用率统计',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/usageStatistics/usageStatistics.vue'),
            // },
            // {
            //   path: '/illegalExitAlarm',
            //   name: 'illegalExitAlarm.vue',
            //   meta: {
            //     title: '非法离开报警',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/illegalExitAlarm/illegalExitAlarm.vue'),
            // },
            // {
            //   path: '/illegalDemolitionAlarm',
            //   name: 'illegalDemolitionAlarm',
            //   meta: {
            //     title: '非法拆除报警',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/illegalDemolitionAlarm/illegalDemolitionAlarm.vue'),
            // },
            // {
            //   path: '/lowPowerWarning',
            //   name: 'lowPowerWarning',
            //   meta: {
            //     title: '低电量预警',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/lowPowerWarning/lowPowerWarning.vue'),
            // },
            // {
            //   path: '/assetActivityTrack',
            //   name: 'assetActivityTrack',
            //   meta: {
            //     title: '资产活动轨迹',
            //   },
            //   component: () => import(/* webpackChunkName: "about" */ '../views/basics/assetActivityTrack/assetActivityTrack.vue'),
            // },
            {
                path: '/homeTitle',
                name: 'homeTitle',
                meta: {
                    title: '自定义标题文案',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/configManage/customTitle/customTitle.vue'),
            },
            {
                path: '/deviceManager',
                name: 'deviceManager',
                meta: {
                    title: '设备管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/deviceManage/deviceManage.vue'),
            },
            {
                path: '/handleTemperatureException',
                name: 'handleTemperatureException',
                meta: {
                    title: '异常温度处理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/handleTemperatureException/handleTemperatureException.vue'),
            },
            // 撮合交易
            //买卖用户管理
            {
                path: '/usermanagement',
                name: 'usermanagement',
                meta: {
                    title: '买家用户管理',
                    keepAlive: false,
                },
                component: () => import('../views/basics/transaction/usermanagement.vue')
            },
            //售卖管理
            {
                path: '/salesmanagement',
                name: 'salesmanagement',
                meta: {
                    title: '售卖管理',
                    keepAlive: true,
                },
                component: () => import('../views/basics/transaction/salesmanagement.vue')
            },
            //订单管理
            // {
            //     path: '/ordermanagement',
            //     name: 'ordermanagement',
            //     meta: {
            //         title: '订单管理',
            //         keepAlive: true,
            //     },
            //     component: () => import('../views/basics/transaction/ordermanagement.vue')
            // },
            //订单详情
            {
                path: '/details',
                name: 'details',
                meta: {
                    title: '订单详情',
                    keepAlive: true,
                },
                component: () => import('../views/basics/transaction/components/details.vue')
            },
            //撮合交易结束
            {
                path: '/labelManager',
                name: 'labelManager',
                meta: {
                    title: '标签管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/labelManage/labelManage.vue'),
            },
            //3.3.6 AI摄像头管理
            {
                path: '/cameraManagement',
                name: 'cameraManagement',
                meta: {
                    title: 'AI摄像头管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/cameraManagement/cameraManagement.vue'),
            },
            //3.3.6 AI摄像头管理
            {
                path: '/cameraDetail',
                name: 'cameraDetail',
                meta: {
                    title: 'AI摄像头管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/cameraManagement/cameraDetail/cameraDetail.vue'),
            },
            //3.3.6 摄像头配置
            {
                path: '/upPackageManagement',
                name: 'upPackageManagement',
                meta: {
                    title: '升级包管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/upPackageManagement/upPackageManage.vue'),
            },
            //3.3.6 升级记录
            {
                path: '/upRecord',
                name: 'upRecord',
                meta: {
                    title: '升级记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/upRecord/upRecord.vue'),
            },
            //3.3.6 升级记录详情
            {
                path: '/upRecordDetail',
                name: 'upRecordDetail',
                meta: {
                    title: '升级记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/upRecord/upRecordDetail/upRecordDetail.vue'),
            },
            {
                path: '/simManage',
                name: 'simManage',
                meta: {
                    title: 'SIM卡管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/simManage/simManage.vue'),
            },
            {
                path: '/nblabelmanage',
                name: 'nblabelmanage',
                meta: {
                    title: 'NB标签管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/nblabelManage/nblabelManage.vue'),
            },
            {
                path: '/countException',
                name: 'countException',
                meta: {
                    title: '点数异常预警',
                    keepAlive: true,
                },
                component: () => import('../views/basics/countException/countException.vue'),
            },

            {
                path: '/temperatureException',
                name: 'temperatureException',
                meta: {
                    title: '温度异常预警',
                    keepAlive: true,
                },
                component: () => import('../views/basics/temperatureException/temperatureException.vue'),
            },
            {
                path: '/nonlicetLeave',
                name: 'nonlicetLeave',
                meta: {
                    title: i18n.t('menu.ai.nonlicetLeave'),
                    keepAlive: true,
                },
                component: () => import('../views/basics/nonlicetLeave/nonlicetLeave.vue'),
            },
            //3.3.3新增
            {
                path: '/lookSeeIllegalDeparture',
                name: 'lookSeeIllegalDeparture',
                meta: {
                    title: i18n.t('menu.ai.disposeIllegalDeparture'),
                    keepAlive: true,
                },
                component: () => import('../views/basics/nonlicetLeave/components/lookSeeIllegalDeparture/lookSeeIllegalDeparture.vue'),
            },
            //3.3.3新增
            {
                path: '/disposeIllegalDeparture',
                name: 'disposeIllegalDeparture',
                meta: {
                    title: i18n.t('menu.ai.disposeIllegalDeparture'),
                    keepAlive: true,
                },
                component: () => import('../views/basics/nonlicetLeave/components/disposeIllegalDeparture/disposeIllegalDeparture.vue'),
            },
            //3.3.3 小程序相关内容
            {
                path: '/merchandiseControl',
                name: 'merchandiseControl',
                meta: {
                    title: '商品管理',
                    keepAlive: true,
                },
                component: () => import('@/views/applet/merchandiseControl/merchandiseControl.vue')
            },
            {
                path: '/merchandiseInfo',
                name: 'merchandiseInfo',
                meta: {
                    title: '商品详情',
                    keepAlive: true,
                },
                component: () => import('@/views/applet/merchandiseControl/components/merchandiseInfo.vue')
            },
            //3.3.3 小程序相关内容
            {
                path: '/orderManagement',
                name: 'orderManagement',
                meta: {
                    title: '订单管理',
                    keepAlive: true,
                },
                component: () => import('../views/applet/orderManagement/orderManagement.vue')
            },
            {
                path: '/orderinfo',
                name: 'orderinfo',
                meta: {
                    title: '查看订单详情',
                    keepAlive: true,
                },
                component: () => import('../views/applet/orderManagement/components/viewOrderInfo.vue')
            },
            //3.3.3 小程序相关内容
            {
                path: '/appletuserManagement',
                name: 'appletuserManagement',
                meta: {
                    title: '用户管理',
                    keepAlive: true,
                },
                component: () => import('../views/applet/userManagement/userManagement.vue')
            },
            {
                path: '/appletUserInfo',
                name: 'appletUserInfo',
                meta: {
                    title: '查看/修改用户',
                    keepAlive: true,
                },
                component: () => import('../views/applet/userManagement/components/ViewAndEditAppletUser.vue')
            },
            //3.3.3 小程序相关内容
            {
                path: '/activityManagement',
                name: 'activityManagement',
                meta: {
                    title: '活动管理',
                    keepAlive: true,
                },
                component: () => import('../views/applet/activityManagement/activityManagement.vue')
            },
            {
                path: '/AddActivityInfo',
                name: 'AddActivityInfo',
                meta: {
                    title: '新增广告',
                    keepAlive: true,
                },
                component: () => import('../views/applet/activityManagement/components/AddActivityInfo/AddActivityInfo.vue')
            },
            {
                path: '/viewActivity',
                name: 'viewActivity',
                meta: {
                    title: '查看广告详情',
                    keepAlive: true,
                },
                component: () => import('../views/applet/activityManagement/components/ViewActivityInfo/ViewActivityInfo.vue')
            },
            {
                path: '/EditActivityInfo',
                name: 'EditActivityInfo',
                meta: {
                    title: '修改广告详情',
                    keepAlive: true,
                },
                component: () => import('../views/applet/activityManagement/components/EditActivityInfo/EditActivityInfo.vue')
            },
            {
                path: '/aiDeath',
                name: 'aiDeath',
                meta: {
                    title: i18n.t('menu.ai.aiDeath'),
                    keepAlive: true,
                },
                component: () => import('../views/basics/aiDeath/aiDeath.vue'),
            },
            {
                path: '/leaveHouse',
                name: 'leaveHouse',
                meta: {
                    title: '离栏预警',
                    keepAlive: true,
                },
                component: () => import('../views/basics/leaveHouse/leaveHouse.vue'),
            },
            {
                path: '/temperatureInfo',
                name: 'temperatureInfo',
                meta: {
                    title: i18n.t('menu.basic.temperatureInfo'),
                    keepAlive: true, //此组件不需要被缓存
                },
                component: () => import('../views/basics/temperatureInfo/temperatureInfo.vue'),
            },
            {
                path: '/claim',
                name: 'claim',
                meta: {
                    title: '保险理赔',
                    keepAlive: true, //此组件不需要被缓存
                },
                component: () => import('../views/basics/claim/claim.vue'),
            },
            {
                path: '/claimDetail',
                name: 'claimDetail',
                meta: {
                    title: '理赔查看',
                    keepAlive: true, //此组件不需要被缓存
                },
                component: () => import('../views/basics/claim/claimDetail/claimDetail.vue'),
            },
            {
                path: '/healthWarning',
                name: 'healthWarning',
                meta: {
                    title: i18n.t('menu.ai.healthWarning'),
                    keepAlive: true, //此组件不需要被缓存
                },
                component: () => import('../views/healthWarning/healthWarning.vue'),
            },
            {
                path: '/medicineInfo',
                name: 'medicineInfo',
                meta: {
                    title: '用药信息管理',
                    keepAlive: true,
                },
                component: () => import('../views/basics/medicineInfo/medicineInfo.vue'),
            },
            {
                path: '/infoRegister',
                name: 'infoRegister',
                meta: {
                    title: i18n.t('menu.basic.infoRegister'),
                    keepAlive: true,
                },
                component: () => import('../views/basics/infoRegister/infoRegister.vue'),
            },
            {
                path: '/infoRegisterDetail',
                name: 'infoRegisterDetail',
                meta: {
                    title: i18n.t('menu.basic.reg_info_title'),
                    keepAlive: true,
                },
                component: () => import('../views/basics/infoRegister/infoRegisterDetail/infoRegisterDetail.vue'),
            },
            {
                path: '/addinfoRegister',
                name: 'addinfoRegister',
                meta: {
                    title: i18n.t('menu.basic.reg_labelNumber'),
                    keepAlive: true, //此组件不需要被缓存
                },
                component: () => import('../views/basics/infoRegister/addinfoRegister.vue'),
            },
            {
                path: '/outManage',
                name: 'outManage',
                meta: {
                    title: '出栏管理',
                    keepAlive: true,
                },
                component: () => import('../views/basics/outManage/outManage.vue'),
            },
            {
                path: '/insure',
                name: 'insure',
                meta: {
                    title: '保险统计',
                    keepAlive: true,
                },
                component: () => import('../views/basics/insure/insure.vue'),
            },
            {
                path: '/insureDetail',
                name: 'insureDetail',
                meta: {
                    title: '保险统计详情',
                    keepAlive: true,
                },
                component: () => import('../views/basics/insure/insureDetail/insureDetail.vue'),
            },

            {
                path: '/traceabilityOutManageDetail',
                name: 'traceabilityOutManageDetail',
                meta: {
                    title: '出栏管理详情',
                    keepAlive: true,
                },
                component: () => import('../views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/traceabilityOutManageDetail.vue'),
            },
            {
                path: '/fowlStatistics',
                name: 'fowlStatistics',
                meta: {
                    title: '禽类统计',
                    keepAlive: true,
                },
                component: () => import('../views/basics/fowlStatistics/fowlStatistics.vue'),
            },
            {
                path: '/livestockPositioning',
                name: 'livestockPositioning',
                meta: {
                    title: '牲畜定位查找',
                    keepAlive: true,
                },
                component: () => import('../views/basics/livestockPositioning/livestockPositioning.vue'),
            },
            {
                path: '/locationMap',
                name: 'locationMap',
                meta: {
                    title: '牲畜定位查找',
                    keepAlive: false,
                },
                component: () => import('../views/basics/livestockPositioning/locationMap.vue'),
            },
            {
                path: '/temperatureInfoDetail',
                // name: 'temperatureInfoDetail',
                meta: {
                    title: i18n.t('menu.basic.hisTemInfo_1'),
                    keepAlive: true,
                },
                component: () => import('../views/basics/temperatureInfo/temperatureInfoDetail/temperatureInfoDetail.vue'),
            },
            {
                path: '/column',
                name: 'column',
                meta: {
                    title: '菜单管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/column/column.vue'),
            },
            {
                path: '/weChatColumn',
                name: 'weChatColumn',
                meta: {
                    title: '微信菜单管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/weChatColumn/column.vue'),
            },
            {
                path: '/role',
                name: 'role',
                meta: {
                    title: '角色管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/role/role.vue'),
            },
            {
                path: '/weChatRole',
                name: 'weChatRole',
                meta: {
                    title: '微信角色管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/weChatRole/role.vue'),
            },
            {
                path: '/personal',
                name: 'personal',
                meta: {
                    title: i18n.t('menu.system.User'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/personal/personal.vue'),
            },
            {
                path: '/appPersonal',
                name: 'appPersonal',
                meta: {
                    title: 'APP用户管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appPersonal/appPersonal.vue'),
            },
            //APP用户管理2
            {
                path: '/appUser',
                name: 'appUser',
                meta: {
                    title: i18n.t('menu.system.App_User'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/AppUser.vue'),
            },
            //APP新增用户  废弃
            // {
            //     path: '/appAddUser',
            //     name: 'appAddUser',
            //     meta: {
            //         title: 'APP新增用户',
            //         keepAlive: true,
            //     },
            //     component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/AppAddUser.vue'),
            // },
            //APP新增用户 重构
            {
                path: '/appAddUser',
                name: 'appAddUser',
                meta: {
                    title: i18n.t('menu.system.add_app_user'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/NewAppAddUser.vue'),
            },
            //App修改用户  重构
            {
                path: '/appSetUser',
                name: 'appSetUser',
                meta: {
                    title: i18n.t('menu.system.edit_app_user'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/NewAppSetUser.vue'),
            },
            //App修改用户  
            // {
            //     path: '/appSetUser',
            //     name: 'appSetUser',
            //     meta: {
            //         title: 'APP修改用户',
            //         keepAlive: true,
            //     },
            //     component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/AppSetUser.vue'),
            // },
            //APP菜单管理
            {
                path: '/appMenu',
                name: 'appMenu',
                meta: {
                    title: 'APP菜单管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/AppMenu/AppMenu.vue'),
            },
            //重构APP菜单管理
            {
                path: '/newappMenu',
                name: 'newappMenu',
                meta: {
                    title: 'APP菜单管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/NewAppMenu.vue'),
            },
            //APP权限管理
            {
                path: '/appJurisdiction',
                name: 'appJurisdiction',
                meta: {
                    title: 'APP权限管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/AppJurisdiction.vue'),
            },
            //银行配置 3.1.5
            {
                path: '/newbankConfig',
                name: 'newbankConfig',
                meta: {
                    title: '银行配置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appfire/BankConfig/BankConfig.vue'),
            },
            //合作商管理
            {
                path: '/PartnerManagement',
                name: 'PartnerManagement',
                component: () => import('../views/system/PartnerManagement/PartnerManagement.vue'),
                meta: {
                    title: '合作商管理',
                    keepAlive: true
                }
            },
            //合作商关联养殖场
            {
                path: '/PartnerLinkFarm',
                name: 'PartnerLinkFarm',
                component: () => import('../views/system/PartnerManagement/PartnerLinkFarm.vue'),
                meta: {
                    title: '关联养殖场',
                    keepAlive: true
                }
            },

            {
                path: '/appPersonalDetail',
                name: 'appPersonalDetail',
                meta: {
                    title: 'APP用户管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appPersonal/appPersonalDetail/appPersonalDetail.vue'),
            },
            {
                path: '/power',
                name: 'power',
                meta: {
                    title: '权限管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/power/power.vue'),
            }, {
                path: '/weChatPower',
                name: 'weChatPower',
                meta: {
                    title: '微信权限管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/weChatPower/power.vue'),
            },
            {
                path: '/personalDetail',
                name: 'personalDetail',
                meta: {
                    title: i18n.t('menu.system.User'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/personal/personalDetail/personalDetail.vue'),
            },
            {
                path: '/medicineConfig',
                name: 'medicineConfig',
                meta: {
                    title: '用药信息管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/medicineConfig/medicineConfig.vue'),
            },
            {
                path: '/media',
                name: 'MediaList',
                component: () => import('../views/configManage/mediaConfig/mediaList.vue'),
                meta: {
                    title: '视频上传',
                    keepAlive: false
                }
            },
            // 大屏预警 3.3.5
            {
                path: '/screenWarning',
                name: 'screenWarning',
                component: () => import('../views/configManage/bigScreenWarningSwitch/BigScreenWarningSwitch.vue'),
                meta: {
                    title: i18n.t('menu.config.Warning_setting'),
                    keepAlive: false
                }
            },
            //防治疾病管理
            {
                path: '/disease',
                name: 'disease',
                meta: {
                    title: '防治疾病管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/disease/disease.vue'),
            },
            {
                path: '/typeConfig',
                name: 'typeConfig',
                meta: {
                    title: '品种类型管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/typeConfig.vue'),
            },
            //牛只预警配置 3.3.5版本变更
            {
                path: '/beefconfig',
                name: 'beefconfig',
                meta: {
                    title: i18n.t('menu.config.Alert_configuration'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/configManage/cattleEarlyWarningConfiguration/cattleEarlyWarningConfiguration.vue'),
            },
            {
                path: '/addbeefconfig',
                name: 'addbeefconfig',
                meta: {
                    title: i18n.t('menu.config.Alert_configuration'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/configManage/cattleEarlyWarningConfiguration/components/cattleEarlyWarningConfigurationAdd.vue'),
            },
            {
                path: '/editbeefconfig',
                name: 'editbeefconfig',
                meta: {
                    title: i18n.t('menu.config.Alert_configuration'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/configManage/cattleEarlyWarningConfiguration/components/cattleEarlyWarningConfigurationEdit.vue'),
            },


            //10.10不知道的页面
            {
                path: '/statisticsPage',
                name: 'statisticsPage',
                meta: {
                    title: '数据统计',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/StatisticsPage.vue'),
            },
            //返利配置信息
            {
                path: '/RebateConfiguration',
                name: 'RebateConfiguration',
                meta: {
                    title: '返利配置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/RebateConfiguration.vue'),
            },
            //返利明细
            {
                path: '/RebateDetails',
                name: 'RebateDetails',
                meta: {
                    title: '返利明细表',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/RebateDetails.vue'),
            },
            //草料上架配置
            {
                path: '/ForageRackConfiguration',
                name: 'ForageRackConfiguration',
                meta: {
                    title: '饲料上架配置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/ForageRackConfiguration.vue'),
            },
            //新增草料
            {
                path: '/AddForageRackConfiguration',
                name: 'AddForageRackConfiguration',
                meta: {
                    title: '新增饲料',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/components/AddForageRackConfiguration.vue'),
            },
            //编辑草料
            {
                path: '/EditForageRackConfiguration',
                name: 'EditForageRackConfiguration',
                meta: {
                    title: '编辑饲料',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/components/EditForageRackConfiguration.vue'),
            },
            //新增药品
            {
                path: '/AddDrug',
                name: 'AddDrug',
                meta: {
                    title: '新增药品',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/components/AddDrug.vue'),
            },
            //编辑药品
            {
                path: '/EditDrug',
                name: 'EditDrug',
                meta: {
                    title: '编辑药品',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/components/EditDrug.vue'),
            },
            //草料需求
            {
                path: '/ForageDemand',
                name: 'ForageDemand',
                meta: {
                    title: '饲料需求',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/ForageDemand.vue'),
            },
            //药品需求
            {
                path: '/DrugDemand',
                name: 'DrugDemand',
                meta: {
                    title: '药品需求',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/DrugDemand.vue'),
            },
            //药品上架配置
            {
                path: '/DrugShelfConfiguration',
                name: 'DrugShelfConfiguration',
                meta: {
                    title: '药品上架配置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/typeConfig/DrugShelfConfiguration.vue'),
            },
            //产奶量统计
            {
                path: '/MilkProductionStatistics',
                name: 'MilkProductionStatistics',
                meta: {
                    title: '产奶量统计',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BreedingModule/MilkProductionStatistics/MilkProductionStatistics.vue'),
            },
            //检疫检查统计
            {
                path: '/QuarantineInspectionStatistics',
                name: 'QuarantineInspectionStatistics',
                meta: {
                    title: '检疫检查统计',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BreedingModule/QuarantineInspectionStatistics/QuarantineInspectionStatistic.vue'),
                //3.2.2临时上线需求  养殖模块  -> 生产管理平台
            },
            {
                path: '/ProductionManagementPlatform',
                name: 'ProductionManagementPlatform',
                meta: {
                    title: '生产管理平台',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/ProductionManagementPlatform.vue'),
            },
            //体重管理 3.3.4 更新
            {
                path: '/weightManagement',
                name: 'weightManagement',
                meta: {
                    title: '体重管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BreedingModule/weightManagement/weightManagement.vue'),
            },
            //体重详情
            {
                path: '/WeightManagementInfo',
                name: 'WeightManagementInfo',
                meta: {
                    title: '体重管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BreedingModule/weightManagement/components/info/WeightManagementInfo.vue'),
            },
            //体重处理
            {
                path: '/WeightManagementUpdate',
                name: 'WeightManagementUpdate',
                meta: {
                    title: '体重管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BreedingModule/weightManagement/components/update/WeightManagementUpdate.vue'),
            },
            //市场列表
            {
                path: '/MarkList',
                name: 'MarkList',
                meta: {
                    title: '市场列表',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/MarketList/MarkList.vue'),
            },
            //订货系统
            //供应商管理
            {
                path: '/SupplierManagement',
                name: 'SupplierManagement',
                meta: {
                    title: '供应商管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BeefOrdering/SupplierManagement/SupplierManagement.vue'),
            },
            {
                path: '/AddMerchant',
                name: 'AddMerchant',
                meta: {
                    title: '新增供应商',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/SupplierManagement/components/AddMerchant/AddMerchant.vue')
            },
            {
                path: '/EditMerchant',
                name: 'EditMerchant',
                meta: {
                    title: '编辑供应商',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/SupplierManagement/components/AddMerchant/AddMerchant.vue')
            },
            // 商品分类
            {
                path: '/CommodityClassification',
                name: 'CommodityClassification',
                meta: {
                    title: '商品分类',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BeefOrdering/CommodityClassification/CommodityClassification.vue'),
            },
            {
                path: '/AddClassification',
                name: 'AddClassification',
                meta: {
                    title: '新增分类',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/CommodityClassification/components/AddClassification/AddClassification.vue')
            },
            {
                path: '/AddClassificationChild',
                name: 'AddClassificationChild',
                meta: {
                    title: '新增分类',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/CommodityClassification/components/AddClassification/AddClassification.vue')
            },
            {
                path: '/EditClassification',
                name: 'EditClassification',
                meta: {
                    title: '编辑分类',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/CommodityClassification/components/AddClassification/AddClassification.vue')
            },
            // 商品列表-商家
            {
                path: '/productList',
                name: 'productList',
                meta: {
                    title: '商品列表-商家',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BeefOrdering/productList/productList.vue'),
            },
            {
                path: '/AddProduct',
                name: 'AddProduct',
                meta: {
                    title: '商品详情',
                    keepAlive: false,
                },
                component: () => import('../views/BeefOrdering/productList/components/ViewAndEditProduct.vue')
            },
            {
                path: '/ViewProduct',
                name: 'ViewProduct',
                meta: {
                    title: '商品详情',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/productList/components/ViewProduct.vue')
            },
            // 商品列表-商家
            {
                path: '/productListweb',
                name: 'productListweb',
                meta: {
                    title: '商品列表-平台',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/BeefOrdering/productListweb/productListweb.vue'),
            },
            {
                path: '/ViewProductWeb',
                name: 'ViewProductWeb',
                meta: {
                    title: '商品详情',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/productListweb/components/ViewProductweb.vue')
            },
            //运费配置
            {
                path: '/FreightAllocation',
                name: 'FreightAllocation',
                meta: {
                    title: '运费配置',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/FreightAllocation/FreightAllocation.vue')
            },
            //运力配置
            {
                path: '/CapacityAllocation',
                name: 'CapacityAllocation',
                meta: {
                    title: '运力配置',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/CapacityAllocation/CapacityAllocation.vue')
            },
            {
                path: '/AddCapacityAllocation',
                name: 'AddCapacityAllocation',
                meta: {
                    title: '新增运力配置',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/CapacityAllocation/components/AddCapacityAllocation/AddCapacityAllocation.vue')
            },
            {
                path: '/EditCapacityAllocation',
                name: 'EditCapacityAllocation',
                meta: {
                    title: '修改运力配置',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/CapacityAllocation/components/EditCapacityAllocation/EditCapacityAllocation.vue')
            },
            //额度管理
            {
                path: '/QuotaManagement',
                name: 'QuotaManagement',
                meta: {
                    title: '额度管理',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/QuotaManagement/QuotaManagement.vue')
            },
            {
                path: '/AddQuotaManagement',
                name: 'AddQuotaManagement',
                meta: {
                    title: '新增门店额度',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/QuotaManagement/components/AddQuotaManagement/AddQuotaManagement.vue')
            },
            {
                path: '/EditQuotaManagement',
                name: 'EditQuotaManagement',
                meta: {
                    title: '修改门店额度',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/QuotaManagement/components/EditQuotaManagement/EditQuotaManagement.vue')
            },
            //品牌管理
            {
                path: '/brandManagement',
                name: 'brandManagement',
                meta: {
                    title: '品牌管理',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/brandManagement/brandManagement.vue')
            },
            {
                path: '/AddBrandManagement',
                name: 'AddBrandManagement',
                meta: {
                    title: '新增品牌',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/brandManagement/components/AddBrandManagement/AddBrandManagement.vue')
            },
            {
                path: '/EditBrandManagement',
                name: 'EditBrandManagement',
                meta: {
                    title: '修改品牌',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/brandManagement/components/EditBrandManagement/EditBrandManagement.vue')
            },
            //财务管理
            {
                path: '/financialManagement',
                name: 'financialManagement',
                meta: {
                    title: '财务管理',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/financialManagement/financialManagement.vue')
            },
            //活动管理
            {
                path: '/activityOrdering',
                name: 'activityOrdering',
                meta: {
                    title: '活动管理',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/activityOrdering/activityOrdering.vue')
            },
            {
                path: '/AddActivityOrderingInfo',
                name: 'AddActivityOrderingInfo',
                meta: {
                    title: '添加广告详情',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/activityOrdering/components/AddActivityInfo/AddActivityInfo.vue')
            },
            {
                path: '/EditActivityOrderingInfo',
                name: 'EditActivityOrderingInfo',
                meta: {
                    title: '修改广告详情',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/activityOrdering/components/EditActivityInfo/EditActivityInfo.vue')
            },
            {
                path: '/ViewActivityOrderingInfo',
                name: 'ViewActivityOrderingInfo',
                meta: {
                    title: '查看广告详情',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/activityOrdering/components/ViewActivityInfo/ViewActivityInfo.vue')
            },
            //门店管理
            {
                path: '/StoreManagement',
                name: 'StoreManagement',
                meta: {
                    title: '门店列表',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/StoreManagement/StoreManagement.vue')
            },
            //售后管理
            {
                path: '/afterSalesManagement',
                name: 'afterSalesManagement',
                meta: {
                    title: '门店列表',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/afterSalesManagement/afterSalesManagementc.vue')
            },
            {
                path: '/SaleInfo',
                name: 'SaleInfo',
                meta: {
                    title: '售后订单详情',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/afterSalesManagement/components/SaleInfo.vue')
            },

            //订货系统用户管理
            {
                path: '/supplyUserManagement',
                name: 'supplyUserManagement',
                meta: {
                    title: '用户管理',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/supplyUserManagement/supplyUserManagement.vue')
            },

            {
                path: '/supplytUserInfo',
                name: 'supplyUserInfo',
                meta: {
                    title: '查看/修改用户',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/supplyUserManagement/components/ViewAndEditSupplyUser.vue')
            },
            //订货系统订单管理
            {
                path: '/beefOrderManagement',
                name: 'beefOrderManagement',
                meta: {
                    title: '订单管理',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/orderManagement/orderManagement.vue')
            },
            {
                path: '/beefOrderinfo',
                name: 'beefOrderinfo',
                meta: {
                    title: '查看订单详情',
                    keepAlive: true,
                },
                component: () => import('../views/BeefOrdering/orderManagement/components/viewOrderInfo.vue')
            },
            // 用户管理
            {
                path: '/userList',
                name: 'userList',
                component: () => import('../views/TradingMarket/UserManage/UserList.vue'),
                meta: {
                    title: '用户管理',
                    keepAlive: true,
                }
            },
            {
                path: '/appletAddUser',
                name: 'appletAddUser',
                meta: {
                    title: '添加用户',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/UserManage/AppletAddUser.vue'),
            },
            {
                path: '/userCheck',
                name: 'userCheck',
                meta: {
                    title: '审核用户',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/UserManage/UserCheck.vue'),
            },
            {
                path: '/appletUserDetail',
                name: 'appletUserDetail',
                meta: {
                    title: '用户详情',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/UserManage/AppletUserDetail.vue'),
            },
            {
                path: '/appletOrder',
                name: 'appletOrder',
                meta: {
                    title: '小程序订单管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/AppletOrder/Index.vue'),
            },
            {
                path: '/BlacklistManage',
                name: 'BlacklistManage',
                meta: {
                    title: '黑名单管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/BlacklistManage/BlacklistManage.vue'),
            },
            {
                path: '/AddBlack',
                name: 'AddBlack',
                meta: {
                    title: '添加黑名单',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/BlacklistManage/AddBlack.vue'),
            },
            {
                path: '/StallManage',
                name: 'StallManage',
                meta: {
                    title: '摊位管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/StallManage/StallManage.vue'),
            },
            {
                path: '/StallInfo',
                name: 'StallInfo',
                meta: {
                    title: '摊位信息设置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/StallManage/StallInfo.vue'),
            },
            {
                path: '/StallInfoTail',
                name: 'StallInfoTail',
                meta: {
                    title: '摊位信息添加编辑',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/StallManage/StallInfoTail.vue'),
            },
            {
                path: '/StallDesign',
                name: 'StallDesign',
                meta: {
                    title: '摊位布局设置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/StallManage/StallDesign.vue'),
            },
            {
                path: '/StallCheck',
                name: 'StallCheck',
                meta: {
                    title: '摊位审核',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/StallManage/StallCheck.vue'),
            },
            //新增市场
            {
                path: '/AddMarket',
                name: 'AddMarket',
                meta: {
                    title: '新增交易市场',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/AddMarket/AddMarket.vue'),
            },
            //编辑市场
            {
                path: '/EditMarket',
                name: 'EditMarket',
                meta: {
                    title: '市场设置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/EditMarket/EditMarket.vue'),
            },
            //交易设置
            {
                path: '/TransactionConfig',
                name: 'TransactionConfig',
                meta: {
                    title: '交易设置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/TradingMarket/TransactionConfig/TransactionConfig.vue'),
            },
            //商品管理
            {
                path: '/CommodityManagementList',
                name: 'CommodityManagementList',
                meta: {
                    title: '商品管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/CommodityManagement/CommodityManagementList/CommodityManagementList.vue'),
            },
            //商品详情
            {
                path: '/CommodityManagementInfo',
                name: 'CommodityManagementInfo',
                meta: {
                    title: '商品详情',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/CommodityManagement/CommodityManagementInfo/CommodityManagementInfo.vue'),
            },
            //商品审核
            {
                path: '/CommodityManagementEditInfo',
                name: 'CommodityManagementEditInfo',
                meta: {
                    title: '商品审核',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/CommodityManagement/CommodityManagementEditInfo/CommodityManagementEditInfo.vue'),
            },
            {
                path: '/surroundingsConfig',
                name: 'surroundingsConfig',
                meta: {
                    title: '品种类型管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/surroundingsConfig/surroundingsConfig.vue'),
            },
            {
                path: '/insureRecord',
                name: 'insureRecord',
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/insureRecord/insureRecord.vue'),
                meta: {
                    title: '保险投保',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/insureRecordDetail',
                name: 'insureRecordDetail',
                component: () => import('../views/basics/insureRecord/insureRecordDetail/insureRecordDetail.vue'),
                meta: {
                    title: '投保审核编辑',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/insuranceBasis',
                name: 'insuranceBasis',
                component: () => import('../views/basics/insuranceBasis/insuranceBasis.vue'),
                meta: {
                    title: '保险基础信息配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //旺牛世家账号管理
            {
                path: '/wnsjAccount',
                name: 'wnsjAccount',
                meta: {
                    title: '旺牛世家账号管理',
                    keepAlive: true,
                },
                component: () => import('../views/basics/wnsjAccount/wnsjAccount.vue')
            },
            {
                path: '/AddwnsjAccount',
                name: 'AddwnsjAccount',
                meta: {
                    title: '添加账号',
                    keepAlive: true,
                },
                component: () => import('../views/basics/wnsjAccount/components/AddwnsjAccount/AddwnsjAccount.vue')
            },
            {
                path: '/EditwnsjAccount',
                name: 'EditwnsjAccount',
                meta: {
                    title: '修改账号',
                    keepAlive: true,
                },
                component: () => import('../views/basics/wnsjAccount/components/EditwnsjAccount/EditwnsjAccount.vue')
            },
            {
                path: '/group',
                name: 'group',
                meta: {
                    title: '组织机构',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/group/group.vue'),
            },
            {
                path: '/farm',
                name: 'farm',
                meta: {
                    title: i18n.t('menu.system.Farm_manage'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/farm/farm.vue'),
            },
            {
                path: '/farmDetail',
                name: 'farmDetail',
                meta: {
                    title: i18n.t('menu.system.Farm_manage'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/farm/farmDetail/farmDetail.vue'),
            },
            {
                path: '/farmAudit',
                name: 'farmAudit',
                meta: {
                    title: '养殖场审核',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/farm/farmAudit/farmAudit.vue'),
            },

            {
                path: '/firm',
                name: 'firm',
                meta: {
                    title: '企业管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/firm/firm.vue'),
            },
            {
                path: '/firmDetail',
                name: 'firmDetail',
                meta: {
                    title: '企业管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/firm/firmDetail/firmDetail.vue'),
            },
            {
                path: '/firmDetail1',
                name: 'firmDetail1',
                meta: {
                    title: '企业管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/transaction/components/firmDetail.vue'),
            },
            {
                path: '/firmAudit',
                name: 'firmAudit',
                meta: {
                    title: '企业审核',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/firm/firmAudit/firmAudit.vue'),
            },
            {
                path: '/firmAudit1',
                name: 'firmAudit1',
                meta: {
                    title: '企业审核',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/basics/transaction/components/firmAudit.vue'),
            },

            {
                path: '/harmless',
                name: 'harmless',
                meta: {
                    title: '无害化处理厂管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/harmless/harmless.vue'),
            },
            {
                path: '/harmlessDetail',
                name: 'harmlessDetail',
                meta: {
                    title: '无害化处理厂管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/harmless/harmlessDetail/harmlessDetail.vue'),
            },
            {
                path: '/harmlessAudit',
                name: 'harmlessAudit',
                meta: {
                    title: '无害化处理厂审核',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/harmless/harmlessAudit/harmlessAudit.vue'),
            },

            {
                path: '/slaughter',
                name: 'slaughter',
                meta: {
                    title: '屠宰场管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/slaughter/slaughter.vue'),
            },

            {
                path: '/slaughterDetail',
                name: 'slaughterDetail',
                meta: {
                    title: '屠宰场管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/slaughter/slaughterDetail/slaughterDetail.vue'),
            },

            {
                path: '/slaughterAudit',
                name: 'slaughterAudit',
                meta: {
                    title: '屠宰场审核',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/slaughter/slaughterAudit/slaughterAudit.vue'),
            },
            {
                path: '/insuranceCompanyConfiguration',
                name: 'insuranceCompanyConfiguration',
                meta: {
                    title: '保险公司配置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/InsuranceCompanyConfiguration/InsuranceCompanyConfiguration.vue'),
            },
            {
                path: '/insuranceDate',
                name: 'insuranceDate',
                meta: {
                    title: '新增/编辑',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/InsuranceCompanyConfiguration/insuranceDate/insuranceDate.vue'),
            },
            {
                path: '/weChatPersonal',
                name: 'weChatPersonal',
                meta: {
                    title: '公众号用户管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/weChatPersonal/personal.vue'),
            },
            {
                path: '/weChatPersonalDetail',
                name: 'weChatPersonalDetail',
                meta: {
                    title: '公众号用户管理详情',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/weChatPersonal/personalDetail/personalDetail.vue'),
            },
            {
                path: '/appVersions',
                name: 'appVersions',
                meta: {
                    title: 'APP系统管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/appVersions/appVersions.vue'),
            },
            {
                path: '/traceabilityOutManage',
                name: 'traceabilityOutManage',
                meta: {
                    title: '出栏管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/traceabilityOutManage/traceabilityOutManage.vue'),
            },
            {
                path: '/slaughterExamine',
                name: 'slaughterExamine',
                meta: {
                    title: '屠宰查验',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/slaughterExamine/slaughterExamine.vue'),
            },
            {
                path: '/slaughterExamineDetail',
                name: 'slaughterExamineDetail',
                meta: {
                    title: '查验详情',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/slaughterExamine/slaughterExamineDetail/slaughterExamineDetail.vue'),
            },
            {
                path: '/harmlessManage',
                name: 'harmlessManage',
                meta: {
                    title: '无害化管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/harmlessManage/harmlessManage.vue'),
            },
            {
                path: '/Filemanagement',
                name: 'Filemanagement',
                component: () => import('../views/bigDataByPro/Filemanagement.vue'),
                meta: {
                    title: i18n.t('menu.breed.animalFileinfoTile'), keepAlive: false, //此组件不需要被缓存
                },
            },
            //通辽  档案详情
            {
                path: '/animalInfo',
                name: 'animalInfo',
                component: () => import('../views/bigDataByPro/animalInfo.vue'),
                meta: {
                    title: '档案管理详情页', keepAlive: false, //此组件不需要被缓存
                },
            },
            //牲畜档案信息
            {
                path: '/allfile',
                name: 'allfile',
                component: () => import('../views/bigDataByPro/allfile.vue'),
                meta: {
                    title: '档案管理',
                    keepAlive: true,
                },
            },
            // 通辽 新牲畜档案信息
            {
                path: '/newAllfile',
                name: 'newAllfile',
                component: () => import('../views/bigDataByPro/newAllfile.vue'),
                meta: {
                    title: i18n.t('menu.basic.newAllfile'),
                    keepAlive: true,
                },
            },
            {
                path: '/harmlessManageDetail',
                name: 'harmlessManageDetail',
                meta: {
                    title: '无害化详情',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/harmlessManage/harmlessManageDetail/harmlessManageDetail.vue'),
            },
            {
                path: '/deathAnimaldetails',
                name: 'deathAnimaldetails',
                meta: {
                    title: i18n.t('menu.breed.deathAnimaldetails'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/deathAnimal/deathAnimaldetails.vue'),
            },
            {
                path: '/deathAnimal',
                name: 'deathAnimal',
                meta: {
                    title: i18n.t('menu.breed.deathAnimal'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/deathAnimal/deathAnimal.vue'),
            },

            //溯源管理  免疫信息
            {
                path: '/immuneRecord',
                name: 'immuneRecord',
                meta: {
                    title: '免疫记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/immuneRecord/immuneRecord.vue'),
            },

            //溯源管理 通辽 新免疫信息
            {
                path: '/newImmuneRecord',
                name: 'newImmuneRecord',
                meta: {
                    title: i18n.t('menu.breed.newImmuneRecord'),
                    keepAlive: true,
                },
                component: () => import('../views/traceabilityManagement/immuneRecord/newImmuneRecord.vue'),
            },
            //溯源管理  治疗信息
            {
                path: '/preventRecord',
                name: 'preventRecord',
                meta: {
                    title: '防治记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/preventRecord/preventRecord.vue'),
            },

            //溯源管理  通辽  治疗   信息
            {
                path: '/newPreventRecord',
                name: 'newPreventRecord',
                meta: {
                    title: i18n.t('menu.breed.newPreventRecord'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/preventRecord/newPreventRecord.vue'),
            },
            //溯源管理  孕检信息
            {
                path: '/pregnancyRecord',
                name: 'pregnancyRecord',
                meta: {
                    title: '孕检记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/pregnancyRecord/pregnancyRecord.vue'),
            },
            //溯源管理 通辽   孕检信息
            {
                path: '/newpregnancyRecord',
                name: 'newpregnancyRecord',
                meta: {
                    title: i18n.t('menu.breed.newpregnancyRecord'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/pregnancyRecord/newPregnancyRecord.vue'),
            },
            //溯源管理  分娩信息
            {
                path: '/deliveryRecord',
                name: 'deliveryRecord',
                meta: {
                    title: '分娩记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/deliveryRecord/deliveryRecord.vue'),
            },
            //溯源管理  通辽  新分娩信息
            {
                path: '/newdeliveryRecord',
                name: 'newdeliveryRecord',
                meta: {
                    title: i18n.t('menu.breed.newdeliveryRecord'),
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/deliveryRecord/newDeliveryRecord.vue'),
            },
            {
                path: '/transferRecord',
                name: 'transferRecord',
                meta: {
                    title: '转舍记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/transferRecord/transferRecord.vue'),
            },
            {
                path: '/obsoleteRecord',
                name: 'obsoleteRecord',
                meta: {
                    title: '淘汰记录',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/obsoleteRecord/obsoleteRecord.vue'),
            },
            {
                path: '/slaughteringInformation',
                name: 'slaughteringInformation',
                meta: {
                    title: '屠宰信息',
                    keepAlive: false,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/traceabilityManagement/slaughteringInformation/slaughteringInformation.vue'),
            },
            {
                path: '/modelTypeManage',
                name: 'modelTypeManage',
                meta: {
                    title: '模型类别管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/aiModel/modelTypeManage/modelTypeManage.vue'),
            },
            {
                path: '/modelTypeManageDetail',
                name: 'modelTypeManageDetail',
                meta: {
                    title: '模型类别管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/aiModel/modelTypeManage/modelTypeManageDetail/modelTypeManageDetail.vue'),
            },
            {
                path: '/benchmarkSetManage',
                name: 'benchmarkSetManage',
                meta: {
                    title: '基准集管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/aiModel/benchmarkSetManage/benchmarkSetManage.vue'),
            },
            {
                path: '/benchmarkSetManageDetail',
                name: 'benchmarkSetManageDetail',
                meta: {
                    title: '基准集管理详情',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/aiModel/benchmarkSetManage/benchmarkSetManageDetail/benchmarkSetManageDetail.vue'),
            },
            {
                path: '/modelArgsManage',
                name: 'modelArgsManage',
                meta: {
                    title: '模型参数管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/aiModel/modelArgsManage/modelArgsManage.vue'),
            },
            {
                path: '/modelArgsManageDetail',
                name: 'modelArgsManageDetail',
                meta: {
                    title: '模型参数管理详情',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/aiModel/modelArgsManage/modelArgsManageDetail/modelArgsManageDetail.vue'),
            },
            {
                path: '/argsBindManage',
                name: 'argsBindManage',
                meta: {
                    title: '模型参数绑定管理',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/aiModel/argsBindManage/argsBindManage.vue'),
            },
            {
                path: '/test',
                name: 'test',
                meta: {
                    title: '测试',
                    keepAlive: true,
                },
                component: () => import('../test/test.vue'),
            },
            {
                path: '/insuranceConfig',
                name: 'insuranceConfig',
                meta: {
                    title: '保险配置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/insurance/insuranceConfig.vue'),
            },
            {
                path: '/editinsuranceConfig',
                name: 'editinsuranceConfig',
                meta: {
                    title: '编辑配置',
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/system/insurance/editinsuranceConfig.vue'),
            },
            {
                path: '/EnvironmentalWarning',
                name: 'EnvironmentalWarning',
                component: () => import('../views/EnvironmentalWarning/EnvironmentalWarning.vue'),
                meta: {
                    title: '环境预警',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/deviceWaring',
                name: 'deviceWaring',
                component: () => import('../views/deviceWaring/deviceWaring.vue'),
                meta: {
                    title: i18n.t('menu.ai.deviceWaring'),
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/insuranceStatistics',
                name: 'insuranceStatistics',
                component: () => import('../views/insuranceStatistics/insuranceStatistics.vue'),
                meta: {
                    title: '保险统计',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Bankconfig',
                name: 'Bankconfig',
                component: () => import('../views/Bankconfig/Bankconfig.vue'),
                meta: {
                    title: '银行配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/editconfig',
                name: 'editconfig',
                component: () => import('../views/Bankconfig/editconfig.vue'),
                meta: {
                    title: '新增/编辑',
                    keepAlive: true, //此组件不需要被缓存

                },
            },

            {
                path: '/Welcome',
                name: 'Welcome',
                component: () => import('../views/Welcome.vue'),
                meta: {
                    title: i18n.t('menu.homeName'),
                    keepAlive: true
                }
            },
            {
                path: '/loansConfig',
                name: 'loansConfig',
                component: () => import('../views/basics/loansConfig/loansConfig.vue'),
                meta: {
                    title: '贷款配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/loansConfigDetail',
                name: 'loansConfigDetail',
                component: () => import('../views/basics/loansConfig/loansConfigDetail/loansConfigDetail.vue'),
                meta: {
                    title: '新增贷款配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/loanapproval',
                name: 'loanapproval',
                component: () => import('../views/basics/loansConfig/loanapproval.vue'),
                meta: {
                    title: '贷款审核',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/loanapprovalDetail',
                name: 'loanapprovalDetail',
                component: () => import('../views/basics/loansConfig/loansConfigDetail/newloanapprovalDetail.vue'),
                meta: {
                    title: '贷款审核',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //ceshi
            {
                path: '/loanapprovalDetailchild',
                name: 'loanapprovalDetailchild',
                component: () => import('../views/basics/loansConfig/loansConfigDetail/components/animalData.vue'),
                meta: {
                    title: '1',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Versions',
                name: 'Versions',
                component: () => import('../views/Versions.vue'),
                meta: {
                    title: 'web版本',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Regulatoryreport',
                name: 'Regulatoryreport',
                component: () => import('../views/basics/Regulatoryreport/Regulatoryreport.vue'),
                meta: {
                    title: '监管报告',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/receiveconfig',
                name: 'receiveconfig',
                component: () => import('../views/basics/Regulatoryreport/receiveconfig.vue'),
                meta: {
                    title: '接收配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/companyConfig',
                name: 'companyConfig',
                component: () => import('../views/basics/companyConfig/companyConfig.vue'),
                meta: {
                    title: '公司配置',
                    keepAlive: true, //此组件不需要被缓存
                }
            },
            {
                path: '/aicheck',
                name: 'aicheck',
                component: () => import('../views/basics/aicheck/aicheck.vue'),
                meta: {
                    title: 'AI人工审核',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            // 贷款管理  3.1.5
            {
                path: '/loanManagement',
                name: 'loanManagement',
                component: () => import('../views/basics/LoanManagement/LoanManagement.vue'),
                meta: {
                    title: '贷款管理',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //延伸需求 3.2.8 新增还款 还款列表 记录
            {
                path: '/addRePayMent',
                name: 'addRePayMent',
                component: () => import('../views/BankModule/addRePayMent/addRePayMent.vue'),
                meta: {
                    title: '还款记录',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //新增板块3.2.8 担保模块
            {
                path: '/GuaranteeBusinessbk',
                name: 'GuaranteeBusinessbk',
                component: () => import('../views/GuaranteeBusinessModule/GuaranteeBusinessbk/GuaranteeBusinessbk.vue'),
                meta: {
                    title: '担保数据看板',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //新增板块3.2.8 担保模块 -> 查看详情
            {
                path: '/GuaranteeBusinessbkInfo',
                name: 'GuaranteeBusinessbkInfo',
                component: () => import('../views/GuaranteeBusinessModule/GuaranteeBusinessbkInfo/GuaranteeBusinessbkInfo.vue'),
                meta: {
                    title: '放款记录',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //新增板块3.2.8 担保模块 -> 查看详情  | 银行模块->查看详情
            {
                path: '/GuaranteeBusinessbkInfo1',
                name: 'GuaranteeBusinessbkInfo1',
                component: () => import('../views/GuaranteeBusinessModule/GuaranteeBusinessbkInfo1/GuaranteeBusinessbkInfo.vue'),
                meta: {
                    title: '查看贷款记录',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/GuaranteeBusinessbkInfo1List',
                name: 'GuaranteeBusinessbkInfo1List',
                component: () => import('../views/GuaranteeBusinessModule/GuaranteeBusinessbkInfo1/GuaranteeBusinessbkInfoList/GuaranteeBusinessbkInfoList.vue'),
                meta: {
                    title: '查看贷款详情',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //3.2.9  硬件管理  ->传感器管理
            {
                path: '/SensorManagement',
                name: 'SensorManagement',
                component: () => import('../views/HardwareModule/SensorManagement/SensorManagement.vue'),
                meta: {
                    title: '传感器管理',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //3.3.0 担保配置
            {
                path: '/GuaranteeAllocation',
                name: 'GuaranteeAllocation',
                component: () => import('../views/configManage/GuaranteeAllocation/GuaranteeAllocation.vue'),
                meta: {
                    title: '担保配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },

            //新增贷款  3.1.5
            {
                path: '/addLoan',
                name: 'addLoan',
                component: () => import('../views/basics/LoanManagement/AddLoan.vue'),
                meta: {
                    title: '新增贷款',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            //新增查看贷款详情
            {
                path: '/viewLoanDetails',
                name: 'viewLoanDetails',
                component: () => import('../views/basics/LoanManagement/ViewLoanDetails.vue'),
                meta: {
                    title: '查看贷款记录',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/aiOestrusEstimate',
                name: 'aiOestrusEstimate',
                component: () => import('../views/basics/aiOestrusEstimate/aiOestrusEstimate.vue'),
                meta: {
                    title: i18n.t('menu.ai.aiOestrusEstimate'),
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/activityCurve',
                name: 'activityCurve',
                component: () => import('../views/basics/aiOestrusEstimate/components/ActivityCurve.vue'),
                meta: {
                    title: i18n.t('menu.ai.activityCurve'),
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/epSuchsign',
                name: 'epSuchsign',
                component: () => import('../views/basics/Suchsign/epSuchsign.vue'),
                meta: {
                    title: '出栏解押审核',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Suchsign',  //3.1.5更改查询接口
                name: 'Suchsign',
                component: () => import('../views/basics/Suchsign/Suchsign.vue'),
                meta: {
                    title: '出栏解押银行审核',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/bankaudit',
                name: 'bankaudit',
                component: () => import('../views/basics/Suchsign/bankaudit.vue'),
                meta: {
                    title: '二审',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/bankdel',
                name: 'bankdel',
                component: () => import('../views/basics/Suchsign/bankdel.vue'),
                meta: {
                    title: '详情',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/enterpriseaudit',
                name: 'enterpriseaudit',
                component: () => import('../views/basics/Suchsign/enterpriseaudit.vue'),
                meta: {
                    title: '初审',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/enterprisedel',
                name: 'enterprisedel',
                component: () => import('../views/basics/Suchsign/enterprisedel.vue'),
                meta: {
                    title: '详情',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/enterprisedeltoo',
                name: 'enterprisedeltoo',
                component: () => import('../views/basics/Suchsign/enterprisedeltoo.vue'),
                meta: {
                    title: '详情',
                    keepAlive: true, //此组件不需要被缓存

                },
            },


            // {
            //     path: '/companyConfig',
            //     name: 'companyConfig',
            //     component: () => import('../views/basics/companyConfig/companyConfig.vue'),
            //     meta: {
            //         title: '公司配置',
            //         keepAlive: true, //此组件不需要被缓存

            //     },
            // },
            {
                path: '/AmortizationCost',
                name: 'AmortizationCost',
                component: () => import('../views/basics/AmortizationCost/AmortizationCost.vue'),
                meta: {
                    title: '服务费摊销报表',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Amortizationdetails',
                name: 'Amortizationdetails',
                component: () => import('../views/basics/AmortizationCost/Amortizationdetails.vue'),
                meta: {
                    title: '服务费摊销详情',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/companyAmortizationCost',
                name: 'AmortizationCost',
                component: () => import('../views/basics/companyAmortizationCost/companyAmortizationCost.vue'),
                meta: {
                    title: '公司往来摊销',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/companyAmortizationdetails',
                name: 'companyAmortizationdetails',
                component: () => import('../views/basics/companyAmortizationCost/companyAmortizationdetails.vue'),
                meta: {
                    title: '公司往来摊销详情',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Financialaudit',
                name: 'Financialaudit',
                component: () => import('../views/basics/Financialaudit/Financialaudit.vue'),
                meta: {
                    title: '审核列表',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Financialauditdetails',
                name: 'Financialauditdetails',
                component: () => import('../views/basics/Financialaudit/Financialauditdetails.vue'),
                meta: {
                    title: '审核详情',
                    keepAlive: true, //此组件不需要被缓存
                },
            },
            {
                path: '/serviceMoneySummarizing',
                name: 'serviceMoneySummarizing',
                component: () => import('../views/financing/serviceMoneySummarizing/serviceMoneySummarizing.vue'),
                meta: {
                    title: '服务费汇总',
                    keepAlive: false, //此组件不需要被缓存


                },
            },
            {
                path: '/Contractmanagement',
                name: 'Contractmanagement',
                component: () => import('../views/basics/Contractmanagement/Contractmanagement.vue'),
                meta: {
                    title: '合同管理',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/Contractmanagementdetails',
                name: 'Contractmanagementdetails',
                component: () => import('../views/basics/Contractmanagement/Contractmanagementdetails.vue'),
                meta: {
                    title: '合同详情',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/addContractmanagement',
                name: 'addContractmanagement',
                component: () => import('../views/basics/Contractmanagement/addContractmanagement.vue'),
                meta: {
                    title: '新增合同',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/upContractmanagement',
                name: 'upContractmanagement',
                component: () => import('../views/basics/Contractmanagement/upContractmanagement.vue'),
                meta: {
                    title: '修改合同',
                    keepAlive: true, //此组件不需要被缓存

                },
            },



            {
                path: '/batchManage',
                name: 'batchManage',
                component: () => import('../views/financing/batchManage/batchManage.vue'),
                meta: {
                    title: '批次管理',
                    keepAlive: true,

                },
            },
            {
                path: '/batchDetail',
                name: 'batchDetail',
                component: () => import('../views/financing/batchManage/batchDetail.vue'),
                meta: {
                    title: '批次详情',
                    keepAlive: true,

                },
            },
            {
                path: '/newBatch',
                name: 'newBatch',
                component: () => import('../views/financing/batchManage/newBatch.vue'),
                meta: {
                    title: '新增批次',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/smsconfig',
                name: 'smsconfig',
                component: () => import('../views/basics/smsconfig/smsconfig.vue'),
                meta: {
                    title: '短信配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/leaveconfig',
                name: 'leaveconfig',
                component: () => import('../views/configManage/leaveconfig/leaveconfig.vue'),
                meta: {
                    title: '非法离开配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/homeep',
                name: 'homeep',
                component: () => import('../views/homepage/homeep.vue'),
                meta: {
                    title: i18n.t('menu.breed.homeep'),
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/homeir',
                name: 'homeir',
                component: () => import('../views/homepage/homeir.vue'),
                meta: {
                    title: '保险数据看板',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/homebk',
                name: 'homebk',
                component: () => import('../views/homepage/homebk.vue'),
                meta: {
                    title: '银行数据大屏',
                    keepAlive: true, //此组件不需要被缓存

                },
            },

            //溯源管理  配种信息
            {
                path: '/breedingHistory',
                name: 'breedingHistory',
                meta: {
                    title: '配种记录',
                    keepAlive: true,
                },
                component: () => import('../views/BreedingHistory/breedingHistory.vue'),
            },
            //溯源管理  通辽新配种信息
            {
                path: '/newbreedingHistory',
                name: 'newbreedingHistory',
                meta: {
                    title: i18n.t('menu.breed.newbreedingHistory'),
                    keepAlive: true,
                },
                component: () => import('../views/BreedingHistory/newBreedingHistory.vue'),
            },
            //溯源管理  通辽  新增发情记录信息
            {
                path: '/newoestrumHistory',
                name: 'newoestrumHistory',
                meta: {
                    title: i18n.t('menu.breed.newoestrumHistory'),
                    keepAlive: true,
                },
                component: () => import('../views/OestrumHistory/newOestrumHistory.vue'),
            },
            // {
            //     path: '/Brandmanagement',
            //     name: 'Brandmanagement',
            //     meta: {
            //         title: '品牌管理',
            //     },
            //     component: () => import('../views/Brandmanagement/Brandmanagement.vue'),
            // },
            // {
            //     path: '/BrandtConfig',
            //     name: 'BrandtConfig',
            //     meta: {
            //         title: '编辑',
            //     },
            //     component: () => import('../views/Brandmanagement/BrandtConfig.vue'),
            // },
            // {
            //     path: '/Livestocklocatingsearch',
            //     name: 'Livestocklocatingsearch',
            //     meta: {
            //         title: '编辑',
            //     },
            //     component: () => import('../views/basics/Livestocklocatingsearch/Livestocklocatingsearch.vue'),
            // },
            {
                path: '/videoConfig',
                name: 'videoConfig',
                component: () => import('../views/videoConfig/videoConfig.vue'),
                meta: {
                    title: '摄像头关联配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/videoinfoConfig',
                name: 'videoinfoConfig',
                component: () => import('../views/videoConfig/videoinfoConfig.vue'),
                meta: {
                    title: '摄像头接入配置',
                    keepAlive: true, //此组件不需要被缓存

                },
            },
            {
                path: '/videoinfo',
                name: 'videoinfo',
                component: () => import('../views/videoConfig/videoinfo.vue'),
                meta: {
                    title: '监控视频查看',
                    keepAlive: false, //此组件不需要被缓存

                },
            },
            {
                path: '/cowInfoManagement',
                name: 'cowInfoManagement',
                component: () => import('../views/basics/cowInfoManagement/cowInfoManagement.vue'),
                meta: {
                    title: '种牛信息管理',
                    keepAlive: true,

                }
            },
            {
                path: '/cowInfoManagementDetail',
                name: 'cowInfoManagementDetail',
                component: () => import('../views/basics/cowInfoManagement/cowInfoManagementDetail/cowInfoManagementDetail.vue'),
                meta: {
                    title: '种牛详细信息',
                    keepAlive: true,
                }
            },
            //牛黄管理
            {
                path: '/bezoarConfig',//牛黄配置
                name: 'bezoarConfig',
                component: () => import('../views/bezoarManagement/bezoarConfig/bezoarConfig.vue'),
                meta: {
                    title: '牛黄拆线/提取配置',
                    keepAlive: true,
                },
            },
            {
                path: '/addBezoarConfig',//牛黄新增配置
                name: 'addBezoarConfig',
                component: () => import('../views/bezoarManagement/bezoarConfig/addConfig/addConfig.vue'),
                meta: {
                    title: '牛黄拆线/提取配置',
                    keepAlive: true,
                },
            },
            {
                path: '/editBezoarConfig',//牛黄修改配置
                name: 'editBezoarConfig',
                component: () => import('../views/bezoarManagement/bezoarConfig/editConfig/editConfig.vue'),
                meta: {
                    title: '牛黄拆线/提取配置',
                    keepAlive: true,
                },
            },
            {
                path: '/bezoarDefaultConfig',//牛黄默认配置
                name: 'bezoarDefaultConfig',
                component: () => import('../views/bezoarManagement/bezoarDefaultConfig/bezoarDefaultConfig.vue'),
                meta: {
                    title: '牛黄默认配置',
                    keepAlive: true,
                },
            },
            {
                path: '/bezoarReminder',//牛黄提醒
                name: 'bezoarReminder',
                component: () => import('../views/bezoarManagement/bezoarReminder/bezoarReminder.vue'),
                meta: {
                    title: '牛黄提醒',
                    keepAlive: true,
                },
            },
            {
                path: '/reminderDetail',//提醒子详情
                name: 'reminderDetail',
                component: () => import('../views/bezoarManagement/bezoarReminder/reminderDetail/reminderDetail.vue'),
                meta: {
                    title: '提醒详情',
                    keepAlive: false,
                },
            },
            {
                path: '/cultivationRecord',//培植记录
                name: 'cultivationRecord',
                component: () => import('../views/bezoarManagement/cultivationRecord/cultivationRecord.vue'),
                meta: {
                    title: '培植记录',
                    keepAlive: true,
                },
            },
            {
                path: '/cultivationDetail',//培植详情
                name: 'cultivationDetail',
                component: () => import('../views/bezoarManagement/cultivationRecord/cultivationDetail/cultivationDetail.vue'),
                meta: {
                    title: '培植详情',
                    keepAlive: false,
                },
            },
            {
                path: '/disconnectionRecord',//拆线记录
                name: 'disconnectionRecord',
                component: () => import('../views/bezoarManagement/disconnectionRecord/disconnectionRecorde.vue'),
                meta: {
                    title: '拆线记录',
                    keepAlive: true,
                },
            },
            {
                path: '/disconnectionDetail',//拆线详情
                name: 'disconnectionDetail',
                component: () => import('../views/bezoarManagement/disconnectionRecord/disconnectionDetail/disconnectionDetail.vue'),
                meta: {
                    title: '拆线详情',
                    keepAlive: false,
                },
            },
            {
                path: '/fetchRecord',//提取记录
                name: 'fetchRecord',
                component: () => import('../views/bezoarManagement/fetchRecord/fetchRecord.vue'),
                meta: {
                    title: '提取记录',
                    keepAlive: true,
                },
            },
            {
                path: '/fetchDetail',//拆线详情
                name: 'fetchDetail',
                component: () => import('../views/bezoarManagement/fetchRecord/fetchDetail/fetchDetail.vue'),
                meta: {
                    title: '提取详情',
                    keepAlive: false,
                },
            },
        ],
    },
    // {
    //     path: '/addAppVer',
    //     name: 'addAppVer',
    //     component: () => import('../views/bigData/components/addAppVer.vue'),
    //     meta: {
    //         title: '新增app',
    //     },
    // },
    // {
    //     path: '/upAppVer',
    //     name: 'upAppVer',
    //     component: () => import('../views/bigData/components/upAppVer.vue'),
    //     meta: {
    //         title: '编辑app',
    //     },
    // },
    {
        path: '/bigDataFarming',
        name: 'bigDataFarming',
        component: () => import('../views/bigData/bigData.vue'),
        meta: {
            title: '数据大屏',
            keepAlive: true,
        },
    },
    {
        path: '/NewbigScreeninsurance',
        name: 'NewbigScreeninsurance',
        component: () => import('../views/NewbigScreeninsurance/NewbigScreeninsurance.vue'),
        meta: {
            title: '数据大屏',
            keepAlive: true,
        },
    },
    {
        path: '/NewbigScreenbank',
        name: 'NewbigScreenbank',
        component: () => import('../views/NewbigScreenbank/NewbigScreenbank.vue'),
        meta: {
            title: '数据大屏',
            keepAlive: true,
        },
    },
    {
        path: '/NewbigScreenenterprise',
        name: 'NewbigScreenenterprise',
        component: () => import('../views/NewbigScreenenterprise/NewbigScreenenterprise.vue'),
        meta: {
            title: '数据大屏',
            keepAlive: true,
        },
    },
    {
        path: '/bigData',
        name: 'bigData',
        component: () => import('../views/bigDataNew/bigDataNew.vue'),
        meta: {
            title: '数据大屏',
            keepAlive: true,
        },
    },
    {
        path: '/bigDataByPro',
        name: 'bigDataByPro',
        component: () => import('../views/bigDataByPro/bigDataByPro.vue'),
        meta: {
            title: '数据大屏',
            keepAlive: true,
        },
    },
    {
        path: '/bigScreenEnterprise',
        name: 'bigScreenEnterprise',
        component: () => import('../views/bigScreenEnterprise/bigScreenEnterprise.vue'),
        meta: {
            title: '数据大屏企业版',
            keepAlive: true,
        },
    },
    {
        path: '/bigScreenGovernment',
        name: 'bigScreenGovernment',
        component: () => import('../views/bigScreenGovernment/bigScreenGovernment.vue'),
        meta: {
            title: '数据大屏政府版',
            keepAlive: true,
        },
    },
    {
        path: '/bigScreenInsurance',
        name: 'bigScreenInsurance',
        component: () => import('../views/bigScreenInsurance/bigScreenInsurance.vue'),
        meta: {
            title: '数据大屏保险版',
            keepAlive: true,
        },
    },
    {
        path: '/bigDataByBank',
        name: 'bigDataByBank',
        component: () => import('../views/bigDataByBank/bigDataByBank.vue'),
        meta: {
            title: '数据大屏银行版',
            keepAlive: true,
        },
    },

    {
        path: '/NewbigScreenenterprise_page',
        name: 'NewbigScreenenterprise',
        component: () => import('../views/newbigScreen/enterprise/NewbigScreenenterprise.vue'),
        meta: {
            title: '数据大屏企业版',
            keepAlive: true,
        },
    },

    {
        path: '/firmScreen',
        name: 'firmScreen',
        component: () => import('../views/largeScreen/firm/firmScreen.vue'),
        meta: {
            title: i18n.t('menu.breed.firmScreen'),
            keepAlive: true,
        },
    },
    //安徽企业大屏
    {
        path: '/firmScreenah',
        name: 'firmScreenah',
        component: () => import('../views/largeScreen/firmanhui/firmScreen.vue'),
        meta: {
            title: '数据大屏企业版',
            keepAlive: true,
        },
    },
    {
        path: '/insuranceScreen',
        name: 'insuranceScreen',
        component: () => import('../views/largeScreen/insurance/insuranceScreen.vue'),
        meta: {
            title: '数据大屏保险版',
            keepAlive: true,
        },
    },
    // 银行大屏
    {
        path: '/bankScreen',
        name: 'bankScreen',
        component: () => import('../views/largeScreen/bankScreen/bankScreen.vue'),
        meta: {
            title: '数据大屏银行版',
            keepAlive: true,
        },
    },
    // 银行大屏（安徽）
    {
        path: '/bankScreenah',
        name: 'bankScreenah',
        component: () => import('../views/largeScreen/anhuiBankScreen/bankScreen.vue'),
        meta: {
            title: '数据大屏银行版',
            keepAlive: true,
        },
    },
    //新银行数据大屏3.1.5
    {
        path: '/bigBankScreen',
        name: 'bigBankScreen',
        component: () => import('../views/largeScreen/NewBigBankScreen/BigBankScreen.vue'),
        meta: {
            title: '数据大屏银行版',
            keepAlive: true,
        },
    },
    {
        path: '/TestVue',
        name: 'TestVue',
        component: () => import('../views/Test/TestVue.vue'),
        meta: {
            title: '数据大屏银行版',
            keepAlive: true,
        },
    },
    {
        path: '/governmentScreen',
        name: 'governmentScreen',
        component: () => import('../views/largeScreen/governmentNew/governmentScreen.vue'),
        meta: {
            title: '数据大屏政府版',
            keepAlive: true,
        },
    },
    //安徽
    {
        path: '/governmentScreenah',
        name: 'governmentScreenah',
        component: () => import('../views/largeScreen/governmentNewanhui/governmentScreen.vue'),
        meta: {
            title: '数据大屏政府版',
            keepAlive: true,
        },
    },
    {
        path: '/ScreenAdress',
        name: 'ScreenAdress',
        component: () => import('../views/largeScreen/newScreen/newScreenAddress.vue'),
        meta: {
            title: '数据大屏测试',
            keepAlive: true
        }
    },
    // D:\work\web\animal\src\views\StaticScreen\Guizhou
    {
        path: '/ScreenAdressGuiZhou',
        name: 'ScreenAdressGuiZhou',
        component: () => import('../views/StaticScreen/Guizhou/guizhouScreen.vue'),
        meta: {
            title: '贵州数据大屏',
            keepAlive: true
        }
    },
    //联通 政府大屏
    {
        path: '/LianTongGovernmentScreen',
        name: 'LianTongGovernmentScreen',
        component: () => import('../views/StaticScreen/liantong/GovernmentScreen/LianTongGovernmentScreen.vue'),
        meta: {
            title: '政府数据大屏',
            keepAlive: true
        }
    },

    //联通 银行大屏
    {
        path: '/LianTongBankScreen',
        name: 'LianTongBankScreen',
        component: () => import('../views/StaticScreen/liantong/BankScreen/LianTongBankScreen.vue'),
        meta: {
            title: '银行数据大屏',
            keepAlive: true
        }
    },

    //联通 保险大屏
    {
        path: '/LianTongInsuranceScreen',
        name: 'LianTongInsuranceScreen',
        component: () => import('../views/StaticScreen/liantong/InsuranceScreen/LianTongInsuranceScreen.vue'),
        meta: {
            title: '保险数据大屏',
            keepAlive: true
        }
    },

    //联通 企业大屏 
    {
        path: '/LianTongEnterpriseScreen',
        name: 'LianTongEnterpriseScreen',
        component: () => import('../views/StaticScreen/liantong/EnterpriseScreen/LianTongEnterpriseScreen.vue'),
        meta: {
            title: '企业数据大屏',
            keepAlive: true
        }
    },
    // 通辽 新牲畜档案信息
    {
        path: '/animalFile',
        name: 'animalFile',
        component: () => import('../views/bigDataByPro/newAllfile.vue'),
        meta: {
            title: '牲畜档案信息',
            keepAlive: true,
        },
    },
    {
        path: '/animalFileInfo',
        name: 'animalFileInfo',
        component: () => import('../views/bigDataByPro/Filemanagement.vue'),
        meta: {
            title: '档案档案详情', keepAlive: false, //此组件不需要被缓存
        },
    },
    {
        path: '/animalTemperatureInfo',
        name: 'animalTemperatureInfo',
        meta: {
            title: '温度信息管理',
            keepAlive: true, //此组件不需要被缓存
        },
        component: () => import('../views/basics/temperatureInfo/temperatureInfo.vue'),
    },
    {
        path: '/animalTemperatureInfoDetail',
        // name: 'temperatureInfoDetail',
        meta: {
            title: i18n.t('menu.basic.hisTemInfo_1'),
            keepAlive: true,
        },
        component: () => import('../views/basics/temperatureInfo/temperatureInfoDetail/temperatureInfoDetailBig.vue'),
    },
    {
        path: '/productComparison',
        // name: 'productComparison',
        meta: {
            title: '产品对比',
            keepAlive: true,
        },
        component: () => import('../views/StaticScreen/ProductComparison.vue'),
    },
    {
        path: '/landingPlan',
        name: 'landingPlan',
        meta: {
            title: '落地案例',
            keepAlive: true,
        },
        component: () => import('../views/StaticScreen/LandingPlan.vue')
    },
    {
        path: '/ecologicalMap',
        name: 'ecologicalMap',
        meta: {
            title: '生态图',
            keepAlive: true,
        },
        component: () => import('../views/StaticScreen/EcologicalMap.vue')
    },
    {
        path: '/blockchainTraceability',
        name: 'blockchainTraceability',
        meta: {
            title: '区块链溯源',
            keepAlive: true,
        },
        component: () => import('../views/StaticScreen/BlockchainTraceability.vue')
    },
    {
        path: '/enterpriseLargeScreen',
        name: 'enterpriseLargeScreen',
        component: () => import('../views/largeScreenNew/enterpriseLargeScreen/enterpriseLargeScreen.vue'),
        meta: {
            title: i18n.t('menu.breed.firmScreen'),
            keepAlive: true,
        },
    },
    {
        path: '/governmentScreenNew',
        name: 'governmentScreenNew',
        component: () => import('../views/largeScreenNew/governmentScreenNew/governmentScreenNew.vue'),
        meta: {
            title: '数据大屏政府版',
            keepAlive: true,
        },
    },
    {
        path: '/governmentVideoList',
        name: 'governmentVideoList',
        component: () => import('../views/largeScreenNew/governmentVideoList/governmentVideoList.vue'),
        meta: {
            title: '数据大屏政府版',
            keepAlive: true,
        },
    },


];

const router = new VueRouter({
    routes,
});

export default router;
